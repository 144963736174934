<div class="px-4 py-3 text-xl border-b border-b-g-1-400">
	<div class="grid grid-cols-2 gap-4">
		<div class="flex justify-content-center">
			<img
				class="w-auto h-[32mm]"
				[src]="article.barcode"
				alt="error generating the barcode"
			/>
		</div>
		<div class="flex flex-col justify-between">
			<div class="flex">
				<span
					[ngClass]="{ 'mt-[13px]': marginCheckbox }"
					class="flex justify-self-center self-center mr-2 w-4 h-4 rounded-sm border border-g-3-500"
				></span>
				@if (!dispensedArticles) {
					<span>
						{{ article.missingQuantity }}
						{{
							(article.missingQuantity > 1
								? 'OUT_OF_STOCK_ARTICLES.PIECES'
								: 'OUT_OF_STOCK_ARTICLES.PIECE'
							) | translate
						}}
					</span>
				}
				@if (dispensedArticles) {
					<span>
						{{ article.dispensedPackages }}
						{{
							(article.dispensedPackages > 1
								? 'DISPENSED_ARTICLES.PIECES'
								: 'DISPENSED_ARTICLES.PIECE'
							) | translate
						}}
					</span>
				}
			</div>
			<div class="break-words">
				<span class="text-sm text-g-1-500">
					{{
						'OUT_OF_STOCK_ARTICLES.TABLE_HEADERS.ARTICLE_CODE'
							| translate
					}}
				</span>
				<br />
				{{ article.articleCode | maxLengthEllipsis: 27 }}
			</div>
		</div>
	</div>
</div>
