import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaxLengthEllipsisPipe } from '../../../pipes/max-length-ellipsis.pipe';

@Component({
	selector: 'app-article-pdf-article',
	templateUrl: './article-pdf-article.component.html',
	standalone: true,
	imports: [NgClass, TranslateModule, MaxLengthEllipsisPipe],
})
export class ArticlePdfArticleComponent {
	@Input() article: any;
	@Input() marginCheckbox: boolean = false;
	@Input() dispensedArticles: boolean = false;

	helper: boolean = false;
}
