@for (articles of articlesMatrix; track i; let i = $index) {
	<div>
		<app-pdf-scaffold
			[marginCheckbox]="marginCheckbox"
			[title]="
				dispensedArticles
					? ('OUT_OF_STOCK_ARTICLES.PRINT.DISPENSED_PRODUCT_LIST'
						| translate)
					: ('OUT_OF_STOCK_ARTICLES.PRINT.OPEN_PRODUCT_LIST'
						| translate)
			"
		>
			<ng-template #body>
				<div class="flex items-center">
					<div class="grid grid-cols-2 gap-x-7">
						@for (article of articles; track i; let i = $index) {
							<div>
								<app-article-pdf-article
									[article]="article"
									[marginCheckbox]="marginCheckbox"
									[dispensedArticles]="dispensedArticles"
								/>
							</div>
						}
					</div>
				</div>
			</ng-template>
		</app-pdf-scaffold>
	</div>
}
